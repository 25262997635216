import { useContext, useEffect, useState } from "react"
import Stats from "./Stats"
import AppContext from "../../AppContext"
import rcc5Year from '../../data/staticData/rcc5Year.csv'
import { parse } from "papaparse"

const StatsSection = () => {
    const [state] = useContext(AppContext)
    const { activeParam: { param }, activeYear: { chartVar: period }, activeRcp: { chartVar: scenario }, activeSeason: { chartVar: season } } = state

    const [statsData, setStatsData] = useState({})
    const [avgData, setAvgData] = useState([])
    const [rangeData, setRangeData] = useState([])


    useEffect(() => {
        console.log(param, scenario, season)
        const avgConfig = {
            complete: (results) => {
                // console.log('parsing averages complete', results);
                const newAverages = results.data.filter((row) => row.rcp === scenario && Number(row.month) === season).map((row) => [Number(row.year), Number(row[`${param}_wmean`])]);
                // setAverages(newAverages);
                // console.log('averages', newAverages);
                setAvgData(newAverages);
            },
            header: true,
            download: true,
            error: (error, file) => {
                console.log('error while parsing', error, file);
            }
        };
        const rangeConfig = { complete: (results) => {
            // console.log('parsing ranges complete', results);
            const newRanges = results.data.filter((row) => row.rcp === scenario && Number(row.month) === season).map((row) => [Number(row.year), Number(row[`${param}_all10`]), Number(row[`${param}_all90`])]);
            setRangeData(newRanges);
            // console.log('ranges', newRanges);
        },
        header: true,
        download: true,
        error: (error, file) => {
            console.log('error while parsing', error, file);
        }}
        parse(rcc5Year, avgConfig);
        parse(rcc5Year, rangeConfig);
    }, [param, scenario, season])

    useEffect(() => {
        let yearSpan = 20
        let minYear = period - yearSpan + 1;
        let maxYear = Number(period); // make sure this is catching 2020
        let count = maxYear === 2100 ? 3 : 4

        let filteredAvg = avgData.filter(item => item[0] >= minYear && item[0] <= maxYear)   
        let average = filteredAvg.length ? (filteredAvg.map(item => item[1]).reduce((a, b) => a + b, 0)) / count : 0;

        let filteredRange = rangeData.filter(item => item[0] >= minYear && item[0] <= maxYear)
        let max = filteredRange.length ? (filteredRange.map(item => item[2]).reduce((a, b) => a + b, 0)) / count : 0;
        let min = filteredRange.length ? (filteredRange.map(item => item[1]).reduce((a, b) => a + b, 0)) / count : 0; 
        setStatsData({average, max, min})
    }, [ period, avgData, rangeData])


    return ( !!Object.keys(statsData).length ? <Stats
        color={state.activeColors}
        title={`${state.activeParam?.statsTitle} for ${state.activeYear.year} ${state.activeParam?.statsUnit}`}
        values={statsData}
    /> :
    <h2>Something has gone wrong while gathering data for stats. Hit refresh to try again.</h2>
    )

}
export default StatsSection