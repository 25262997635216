import { useEffect, useRef } from "react"
import FeatureLayer from '@arcgis/core/layers/FeatureLayer'
import Map from '@arcgis/core/Map'
import MapView from '@arcgis/core/views/MapView'
import Legend from '@arcgis/core/widgets/Legend'
import styled from "styled-components";
// import "@esri/calcite-components/dist/components/calcite-loader";
// import { CalciteLoader } from "@esri/calcite-components-react";

const Styles = {
  InsetMap: styled.div`
  height: 65rem;
  width: 40rem;
  margin: 0;
  padding: 2rem 0;
  margin-bottom: 2rem;

  @media(max - width: $breakpoint - tablet) {
    width: 90 %;
    margin: auto;
  }
  `,
  LiveMap: styled.div`
    height: 65rem;
    width: 40rem;
    min-width: 30rem;
    margin: 0;
    border: 1px solid lightgray;
    padding: 5px;
    overflow: hidden;
    margin-bottom: 5rem;

    @media(max-width: 700) {
      width: 90%;
      margin: auto;
  }
    `,
}

const InsetMap = ({ layerId, portalId, title }) => {

  // console.log('test', portalId, layerId, title)
  const mapRef = useRef(null)
  const legendRef = useRef(null)

  useEffect(() => {
    //** */ create the layers
    let counties = new FeatureLayer({
      legendEnabled: false,
      portalItem: {
        id: '16919ceb172c4b718c124e77086138f8'
      },
      opacity: .5,
      popupEnabled: false,

    })

    const labelClass = {
      // autocasts as new LabelClass()
      symbol: {
        type: "text",  // autocasts as new TextSymbol()
        color: "white",
        haloColor: 'black',
        haloSize: 2,
        font: {  // autocast as new Font()
          // family: "Playfair Display",
          size: 12,
          // weight: "bold"
        }
      },
      labelPlacement: "always-horizontal",
      labelExpressionInfo: {
        expression: "Round($feature.Upper_Value, 2);"
      }
    };

    if (!title?.includes("Change")) {
      labelClass.labelExpressionInfo.expression = "Round($feature.UpperValue, 2);"
    }

    let theLayer = new FeatureLayer({
      opacity: .7,
      portalItem: { id: portalId },
      layerId: layerId,
      title: title,
      labelsVisible: true,
      visible: true,
      popupEnabled: false,
    })

    theLayer.when(() => {
      let fields = theLayer.fields.map(item => item.name)
      
      if (fields.includes('Upper_Value')) {
        labelClass.labelExpressionInfo.expression = "Round($feature.Upper_Value, 2);"
      } else if (fields.includes('UpperValue')) {
        labelClass.labelExpressionInfo.expression = "Round($feature.UpperValue, 2);"
      } else {
        console.log('field upper value not found', fields)
      }
      theLayer.labelingInfo = [labelClass];
    })

    //** */ create the map
    let theMap = new Map({
      basemap: 'topo-vector',
      layers: [theLayer, counties]
    })
    // ** Create the View
    let theView = new MapView({
      center: [-74.7, 40.1],
      zoom: 8,
      map: theMap,
    })
    theView.ui.empty('top-left')
    // theView.ui.add(staticLegend, 'top-left')
    // freeze the view
    theView.on('mouse-wheel', (evt) => {
      evt.stopPropagation()
    })
    theView.container = mapRef.current

    let staticLegend = new Legend({
      view: theView,
      style: {
        type: "card",
        layout: "side-by-side"
      }
    })
    if (legendRef.current) {
      legendRef.current.innerHTML = ''
      staticLegend.container = legendRef.current
    }

    return () => {
      theView.destroy()
    }
  }, [portalId, layerId, title])

  return (
    <Styles.InsetMap >
      <div ref={legendRef}></div>
      {/* <CalciteLoader hidden={!!imageSrc}></CalciteLoader> */}
      <div style={{ position: 'relative' }}>
        {/* <img style={{ zIndex: 1 }} src={imageSrc} alt='map of new jersey' ref={imgRef} /> */}
        <Styles.LiveMap ref={mapRef}></Styles.LiveMap>
      </div>
    </Styles.InsetMap>
  );
}

export default InsetMap