const pagesArray = [
  // pcpn
  {
    id: 1,
    tileTitle: "Mean Seasonal Precipitation",
    param: "pcpn",
    tileImage:
      "https://njclimateresourcecenter.rutgers.edu/wp-content/uploads/2022/04/Precipitation-icon.png",
    title:
      "Climate Change Impacts on Mean Seasonal Precipitation in New Jersey ",
    what: "More about mean seasonal precipitation",
    description: `With climate change, warmer temperatures allow the atmosphere to hold more water vapor, potentially increasing seasonal precipitation. In recent decades, the annual precipitation in New Jersey has generally increased compared to the prior century. Compared to today, climate projections indicate that New Jersey is expected to experience increased precipitation mainly in the winter and spring seasons through 2100 with both moderate and high greenhouse gas emissions. Higher emission projections generate higher winter and spring mean precipitation compared to moderate emissions. Increased precipitation can cause more flooding and lead to flooding impacts such as power outages, displacement of residents, and/or crop loss.`,
    topText:
      "Mean seasonal precipitation is the average amount of rainfall experienced in the winter, spring, summer, and fall seasons throughout the state. Average rainfall during the winter and spring seasons are expected to increase slightly by 2100. Summer and fall are not expected to experience much change in rainfall by 2100.",
    belowViz:
      "Rainfall in every season is highly variable from year to year in New Jersey. The range of projections is very wide, highlighting how variable and uncertain projections in mean seasonal rainfall are. However, what is more certain is that extreme rainfall is projected to become more intense throughout the rest of the century. An example of an extreme rainfall event would be where the average amount of 3 months worth of rain falls in a 3 hour period.",
    //  For more information on extreme rainfall, please see our Extreme Rainfall Tool (Link).   ',
    mapTitle: "Average Precipitation (Inches)",
    statsTitle: "Seasonal Precipitation",
    statsUnit: "inches",

    chartTitle: "Precipitation",
    chartYAxisText: "Total seasonal precipitation, inches",
    chartYAxisMax: 30,
    about: {
      desc: `With this tool, the simulated mean of seasonal precipitation can be viewed for the whole of New Jersey over historical and future time periods. Note the historical period of 1991-2020 is climate model data and not based solely on observed precipitation measurements. The future time periods are simulated using greenhouse gas emissions scenarios RCP 4.5 (moderate emissions) and RCP 8.5 (high emissions). The modeled data have been extracted from the U.S. National Oceanic and Atmospheric Administration's Applied Climate Information System and localized to New Jersey. `,
      variables: [
        {
          title: "Mean Precipitation (inches)",
          desc: [
            "Mean daily precipitation is simulated using a grid over the state. The mean daily precipitation is totaled for the selected season at each grid cell. The statewide seasonal mean is calculated by averaging these grid cell values over the whole state. The 10th through 90th percentile range of simulated seasonal mean precipitation is represented by the light blue shading.",
          ],
        },
      ],
    },
    changeLegend: {
      title: "Change in inches",
      range: [
        "#a6611a",
        "#c3924b",
        "#dfc27d",
        "#eadcb9",
        "#f5f5f5",
        "#bbe1db",
        "#80cdc1",
        "#40a999",
        "#018571",
      ],
      domain: ["-4", "-3", "-2", "-1", " 0", "+1", "+2", "+3", "+4"],
    },
    meanLegend: {
      title: "Precipitation in inches",
      range: [
        "#e8e8e8",
        "#e2eef9",
        "#cde0f2",
        "#b0d2e8",
        "#89bfdd",
        "#60a6d2",
        "#3e8ec4",
        "#2172b6",
        "#0a549e",
        "#08306b",
      ],
      domain: ["9", "10", "11", "12", "13", "14", "15", "16", "17", "18"],
    },
    portalId: '23678ab88f2841de90368e952b95e48d'
  },
  // maxt
  {
    id: 2,
    tileTitle: "Mean Daily Maximum Temperature",
    tileImage:
      "https://njclimateresourcecenter.rutgers.edu/wp-content/uploads/2022/04/daily-max-temp-icon.png",
    what: "More about mean daily maximum temperature",
    param: "maxt",
    title:
      "Climate Change Impacts on Mean Daily Maximum Temperature in New Jersey",
    description: `Seasonal mean daily maximum temperatures (the hottest part of the day) have been generally increasing in New Jersey since 1900 and are projected to continue increasing through 2100 with both moderate and high greenhouse gas emissions. Higher emission projections generate higher seasonal mean daily maximum temperatures compared to moderate emissions through 2100. Higher temperatures can lead to increased incidences of heat-related illnesses, such as heat stroke, and worse air quality.`,
    topText:
      "The mean seasonal daily maximum temperature is the average of the daily hottest temperature experienced in the winter, spring, summer, and fall seasons throughout the state. The average daily maximum temperatures have been generally increasing in New Jersey in recent decades and are projected to continue through 2100 across all seasons, with higher emissions causing a greater increase in temperatures.",
    belowViz:
      "The range of projected values is variable between seasons but highlights high confidence in increasing maximum temperature trends.",
    mapTitle: "Average Maximum Temperature (˚F)",
    statsTitle: "Seasonal Maximum Temperature",
    statsUnit: "degrees",
    chartTitle: "Maximum Temperature",
    chartYAxisText: "Total seasonal maximum temperature",
    chartYAxisMax: 100,
    about: {
      desc: `With this tool, the simulated mean of seasonal maximum daily temperatures can be viewed for the whole of New Jersey over historical and future time periods. Note the historical period of 1991-2020 is climate model data and not based solely on observed temperature measurements. The future time periods are simulated using greenhouse gas emissions scenarios RCP 4.5 (moderate emissions) and RCP 8.5 (high emissions). The modeled data have been extracted from the U.S. National Oceanic and Atmospheric Administration's Applied Climate Information System and localized to New Jersey. `,
      variables: [
        {
          title: "Maximum Daily Temperatures (°F) ",
          desc: [
            `Mean maximum daily temperature is simulated using a grid over the state. The mean daily maximum temperature for the selected season is computed at each grid cell. The statewide seasonal mean is calculated by averaging these grid cell values over the whole state. The 10th through 90th percentile range of simulated seasonal mean maximum daily temperature values is represented by the light blue shading.`,
          ],
        },
      ],
    },
    changeLegend: {
      title: "Change in ˚F",
      range: [
        "#fef0d9",
        "#fedeb2",
        "#fdcc8a",
        "#fdad71",
        "#fc8d59",
        "#f06b46",
        "#e34a33",
        "#cb2519",
        "#b30000",
      ],
      domain: ["0", "+1", "+3", "+4", "+6", "+7", "+8", "+10", "+11"],
    },
    meanLegend: {
      title: "Max Temperature ˚F",
      range: [
        "#ffffb2",
        "#ffe687",
        "#fecc5c",
        "#fead4c",
        "#fd8d3c",
        "#f7642e",
        "#f03b20",
        "#d71d23",
        "#bd0026",
      ],
      domain: ["34", "42", "50", "58", "66", "73", "81", "89", "97"],
    },
    portalId: '78d9b9e4c87e477cad055b665f6302c2',
  },
  // mint
  {
    id: 3,
    tileTitle: "Mean Daily Minimum Temperature",
    tileImage:
      "https://njclimateresourcecenter.rutgers.edu/wp-content/uploads/2022/04/daily-min-temp-icon.png",
    what: "More about mean daily minimum temperature",
    param: "mint",
    title:
      "Climate Change Impacts on Mean Daily Minimum Temperature in New Jersey",
    description: `Seasonal mean daily minimum temperatures (the coldest part of the day, most often at night) have been generally increasing in New Jersey since 1900 and are projected to continue increasing through 2100 with both moderate and high greenhouse gas emissions. They are projected to continue increasing at a greater rate than daily maximum temperatures with future climate change. Higher emission projections are connected to higher seasonal mean daily minimum temperatures compared to moderate emissions through 2100. Higher overnight temperatures are associated with greater incidences of heat-related illnesses, negative health outcomes, and sleep disruption.`,

    topText:
      "The mean seasonal daily minimum temperature is the average of the daily coldest temperature experienced in the winter, spring, summer, and fall seasons throughout the state. The average daily minimum temperatures have been generally increasing in New Jersey in recent decades and are projected to continue through 2100 across all seasons, with high emissions causing a greater increase in temperatures.",
    belowViz:
      "The range of projected values is variable between seasons but highlights high confidence in increasing minimum temperature trends.",
    mapTitle: "Average Minimum Temperature (˚F)",
    statsTitle: "Seasonal Minimum Temperature",
    statsUnit: "degrees",
    chartTitle: "Minimum Temperature",
    chartYAxisText: "Total seasonal minimum temperature",
    chartYAxisMax: 80,
    about: {
      desc: `With this tool, the simulated mean of seasonal minimum daily temperatures can be viewed for the whole of New Jersey over historical and future time periods. Note the historical period of 1991-2020 is climate model data and not based solely on observed temperature measurements. The future time periods are simulated using greenhouse gas emissions scenarios RCP 4.5 (moderate emissions) and RCP 8.5 (high emissions). The modeled data have been extracted from the U.S. National Oceanic and Atmospheric Administration's Applied Climate Information System and localized to New Jersey.`,
      variables: [
        {
          title: "Minimum Daily Temperatures (°F)",
          desc: [
            `Mean minimum daily temperature is simulated using a grid over the state. The mean daily minimum temperature for the selected season is computed at each grid cell. The statewide seasonal mean is calculated by averaging these grid cell values over the whole state. The 10th through 90th percentile range of simulated seasonal mean minimum daily temperature values is represented by the light blue shading.`,
          ],
        },
      ],
    },
    changeLegend: {
      title: "Change in ˚F",
      range: [
        "#0868ac",
        "#2585bb",
        "#43a8ca",
        "#5fb7c7",
        "#7bccc4",
        "#9bd8c0",
        "#bae4bc",
        "#d5efd2",
        "#f0f9e8",
      ],
      domain: ["0", "+1", "+3", "+4", "+5", "+6", "+8", "+9", "+10"],
    },
    meanLegend: {
      title: "Min Temperature ˚F",
      range: [
        "#253494",
        "#2859a6",
        "#2c7fb8",
        "#369bbe",
        "#41b6cf",
        "#71c8bc",
        "#a1dab4",
        "#d0edc0",
        "#ffffcc",
      ],
      domain: ["15", "23", "31", "39", "47", "54", "62", "70", "78"],
    },
    portalId: ''
  },
  // cdd
  {
    id: 4,
    tileTitle: "Mean Cooling Degree Days",
    tileImage:
      "https://njclimateresourcecenter.rutgers.edu/wp-content/uploads/2022/04/fan-icon.png",
    what: "More about mean cooling degree days",
    param: "cdd",
    title: "Climate Change Impacts on Mean Cooling Degree Days in New Jersey",
    description: `Cooling degree days provide a measure to relate the outdoor temperature to the energy demand to cool a building.  Primarily applicable during the spring through fall, more cooling degree days means that a building needs to use more energy to adequately cool the interior to comfortable conditions. The number of seasonal cooling degree days has been generally increasing in New Jersey since 1900 and is projected to increase through 2100 with both moderate and high increases in greenhouse gas emissions. Higher emission projections generate more seasonal mean cooling degree days compared to moderate emissions through 2100. This trend will increase the energy demands for cooling, potentially stressing energy generation and utility , infrastructure.`,
    topText:
      "Cooling degree days provide a measure to relate the outdoor temperature to the energy demand to cool a building to a comfortable temperature of 65 °F. Seasonal mean cooling degree days have been generally increasing in New Jersey in recent decades and are projected to continue through 2100 in the spring, summer, and fall seasons, with high emissions causing a greater increase in temperatures. The winter values are effectively zero as building generally do not require cooling in the winter.",
    belowViz:
      "The range of projections is variable between seasons but highlights high confidence in increasing cooling degree day trends.",
    mapTitle: "Average Cooling Degree Days",
    statsTitle: "Seasonal Cooling",
    statsUnit: "degree days",
    chartTitle: "Cooling Degree Days",
    chartYAxisText: "Total seasonal cooling degree days",
    chartYAxisMax: 2500,
    about: {
      desc: `With this tool, the simulated mean seasonal cooling degree days can be viewed for the whole of New Jersey over historical and future time periods. Note the historical period of 1991-2020 is climate model data and not based solely on observed temperature measurements. The future time periods are simulated using greenhouse gas emissions scenarios RCP 4.5 (moderate emissions) and RCP 8.5 (high emissions). The modeled data have been extracted from the U.S. National Oceanic and Atmospheric Administration's Applied Climate Information System and localized to New Jersey. `,
      variables: [
        {
          title: "Cooling Degree Days (CDD)",
          desc: [
            `Cooling degree days (CDD) are way to quantify the intensity of cooling needs over a period of time, presented above at a seasonal scale. The CDD for a single day are calculated as the mean daily temperature minus 65°F. So, a daily mean temperature of 75°F yields 10 CDD, providing a metric that can be related to energy demand to cool the building back to 65°F. 65°F is used as a basis for the CDD calculation because it is an outdoor temperature generally regarded as comfortable. In this tool, the CDD for each day is totaled across a season, and negative CDD values are not incorporated because CDD are only meant to quantify the time and intensity of outdoor temperatures above 65°F. The winter season in New Jersey does not typically require cooling as mean daily temperatures rarely exceed 65°F.`,
            `Mean Cooling Degree Days are simulated using a grid over the state. The mean cooling degree days for the selected season are computed at each grid cell. The statewide seasonal mean is calculated by averaging these grid cell values over the whole state. The 10th through 90th percentile range of simulated seasonal CDD values is represented by the light blue shading. `,
          ],
        },
      ],
    },
    changeLegend: {
      title: "Change in Degree Days",
      range: [
        "#008837",
        "#53b26b",
        "#a6dba0",
        "#cfe9cc",
        "#f7f7f7",
        "#ddcee3",
        "#c2a5cf",
        "#9f6bb2",
        "#7b3294",
      ],
      domain: [
        "-885",
        "-664",
        "-443",
        "-221",
        "0",
        "+221",
        "+443",
        "+664",
        "+885",
      ],
    },
    meanLegend: {
      title: "Cooling Degree Days",
      range: [
        "#ffffb2",
        "#ffe98c",
        "#ffd265",
        "#feb751",
        "#fe9b43",
        "#fb7b35",
        "#f55629",
        "#eb3420",
        "#d41a23",
        "#bd0026",
      ],
      domain: [
        "0",
        "218",
        "436",
        "653",
        "871",
        "1089",
        "1307",
        "1524",
        "1742",
        "1960",
      ],
    },
  },
  // gdd
  {
    id: 5,
    tileTitle: "Mean Growing Degree Days",
    tileImage:
      "https://njclimateresourcecenter.rutgers.edu/wp-content/uploads/2022/04/growing-dats-icon.png",
    what: "More about mean growing degree days",
    param: "gdd",
    title: "Climate Change Impacts on Mean Growing Degree Days in New Jersey",
    description: `Growing degree days measure heat accumulation and are used to estimate the growth and development of crops and pests throughout the growing season. Growing degree days provide a physiological basis to project, for example, when a crop may mature as well as an estimate of when pests may reach maturity, break winter dormancy, or when pest eggs hatch.  Primarily applicable during the spring through fall growing season, more growing degree days means that plant and pest development will likely occur at a faster rate, absent extreme weather conditions. The number of seasonal growing degree days has been generally increasing in New Jersey since 1900 and is projected to increase through 2100 with both moderate and high increases in greenhouse gas emissions. Higher emission projections generate more seasonal mean growing degree days compared to moderate emissions through 2100. Increased growing degree days will likely shift crop pest emergence earlier in the year, shift crop harvests earlier in the traditional growing season, and lengthen the growing season, potentially allowing for additional harvests.`,
    topText:
      "Growing degree days measure heat accumulation of temperatures above 50 °F during a season and are used as a physiological basis to estimate the growth and development of crops and pests. Seasonal growing degree days have been generally increasing in New Jersey in recent decades and are projected to continue through 2100 in the spring, summer, and fall seasons, with high emissions causing a greater increase in growing degree days. The winter values are very low as daily average temperatures rarely exceed 50 °F in during the winter season.",
    belowViz:
      "The bold line in the graph shows 5-year means of seasonal growing degree days projections of 31 different climate models. The shaded region indicates the 10th to 90th percentile of the mean range of all climate models for each 5-year period. The range is variable between seasons but highlights high confidence in increasing growing degree day trends.",
    mapTitle: "Average Growing Degree Days",
    statsTitle: "Seasonal Growing",
    statsUnit: "degree days",
    chartTitle: "Growing Degree Days",
    chartYAxisText: "Total seasonal growing degree days",
    chartYAxisMax: 4000,
    about: {
      desc: `With this tool, the simulated mean seasonal growing degree days can be viewed for the whole of New Jersey over historical and future time periods. Note the historical period of 1991-2020 is climate model data and not based solely on observed temperature measurements. The future time periods are simulated using greenhouse gas emissions scenarios RCP 4.5 (moderate emissions) and RCP 8.5 (high emissions). The modeled data have been extracted from the U.S. National Oceanic and Atmospheric Administration's Applied Climate Information System and localized to New Jersey.`,
      variables: [
        {
          title: "Growing Degree Days (GDD)",
          desc: [
            `Growing degree days (GDD) quantify the heat accumulation that plants and insects experience over the growing season. Heat accumulation drives plant and insect growth, crop development, and the timing of other physiological processes such as flowering. Here they are presented at the seasonal (winter, spring, summer, fall) scale. The GDD for a single day are calculated as the mean daily temperature minus 50°F. So, a daily mean temperature of 65°F yields 15 GDD. 50°F is a commonly used as a basis for the GDD calculation as it represents the minimum temperature threshold for growth in multiple crops (e.g. corn and soybeans). In this tool, the GDD for each day is totaled across a season, and negative GDD values are not incorporated because GDD are only meant to quantify the time and intensity of temperatures above 50°F that drive plant and insect growth.`,
            `Mean Growing Degree Days are simulated using a grid over the state. The mean growing degree days for the selected season are computed at each grid cell. The statewide seasonal mean is calculated by averaging these grid cell values over the whole state. The 10th through 90th percentile range of simulated seasonal GDD values is represented by the light blue shading. `,
          ],
        },
      ],
    },
    changeLegend: {
      title: "Change in Degree Days",
      range: [
        "#fde725",
        "#b5de2c",
        "#6cce59",
        "#35b779",
        "#1e9e89",
        "#25838e",
        "#31688e",
        "#3e4a89",
        "#472878",
        "#440154",
      ],
      domain: [
        "0",
        "+102",
        "+204",
        "+306",
        "+408",
        "+509",
        "+611",
        "+713",
        "+815",
        "+917",
      ],
    },
    meanLegend: {
      title: "Growing Degree Days",
      range: [
        "#ffffcc",
        "#e4f4b6",
        "#c9e99f",
        "#a9dc8e",
        "#88cd80",
        "#68be71",
        "#48af60",
        "#2b9d51",
        "#158244",
        "#006837",
      ],
      domain: [
        "2",
        "373",
        "743",
        "1114",
        "1485",
        "1855",
        "2226",
        "2597",
        "2967",
        "3338",
      ],
    },
  },
  // hdd
  {
    id: 6,
    tileTitle: "Mean Heating Degree Days",
    tileImage:
      "https://njclimateresourcecenter.rutgers.edu/wp-content/uploads/2022/04/heating-days-icon.png",
    what: "More about mean heating degree days",
    param: "hdd",
    title: "Climate Change Impacts on Mean Heating Degree Days in New Jersey",
    description: `Heating degree days provide a measure to relate the outdoor temperature to the energy demand to heat a building.  Primarily applicable during the fall through spring, more heating degree days means that a building needs to use more energy to adequately warm the interior to comfortable conditions. The number of seasonal heating degree days has been generally decreasing in New Jersey since 1900 and is projected to decrease through 2100 with both moderate and high increases in greenhouse gas emissions. Higher emission projections generate fewer seasonal mean heating degree days compared to moderate emissions through 2100. This trend will potentially lower the energy demands for heating in New Jersey.`,
    topText:
      "Heating degree days provide a measure to relate the outdoor temperature to the energy demand to warm a building to a comfortable temperature of 65 °F. Seasonal mean heating degree days have been generally decreasing in New Jersey in recent decades and are projected to continue through 2100 in the fall, winter, and spring seasons, with high emissions causing a greater decrease in heating degree days. The summer values are effectively zero as building generally do not require heating in the summer.",
    belowViz:
      "The bold line in the graph shows 5-year means of seasonal heating degree days projections of 31 different climate models. The shaded region indicates the 10th to 90th percentile of the mean range of all climate models for each 5-year period. The range is variable between seasons but highlights high confidence in decreasing heating degree day trends.",
    mapTitle: "Average Heating Degree Days",
    statsTitle: "Seasonal Heating",
    statsUnit: "degree days",
    chartTitle: "Heating Degree Days",
    chartYAxisText: "Total seasonal heating degree days",
    chartYAxisMax: 4000,

    about: {
      desc: `With this tool, the simulated mean seasonal heating degree days can be viewed for the whole of New Jersey over historical and future time periods. Note the historical period of 1991-2020 is climate model data and not based solely on observed temperature measurements. The future time periods are simulated using greenhouse gas emissions scenarios RCP 4.5 (moderate emissions) and RCP 8.5 (high emissions). The modeled data have been extracted from the U.S. National Oceanic and Atmospheric Administration's Applied Climate Information System and localized to New Jersey. `,
      variables: [
        {
          title: "Heating Degree Days (HDD)",
          desc: [
            `Heating degree days (HDD) are way to quantify the intensity of heating needs over a period of time, presented at a seasonal scale. The HDD for a single day are calculated as 65°F minus mean daily temperature. So, a daily mean temperature of 55°F yields 10 HDD, providing a metric that can be related to energy demand to warm the building back to 65°F. 65°F is used as a basis for the HDD calculation because it is an outdoor temperature generally regarded as comfortable. In this tool, the HDD for each day is totaled across a season, and negative HDD values are not incorporated because HDD are only meant to quantify the time and intensity of outdoor temperatures below 65°F. The summer season in New Jersey does not typically require heating as mean daily summer temperatures are rarely lower than 65°F.`,
            `Mean Heating Degree Days are simulated using a grid over the state. The mean heating degree days for the selected season are computed at each grid cell. The statewide seasonal mean is calculated by averaging these grid cell values over the whole state. The 10th through 90th percentile range of simulated seasonal HDD values is represented by the light blue shading.`,
          ],
        },
      ],
    },
    changeLegend: {
      title: "Change in Degree Days",
      range: [
        "#000004",
        "#180f3e",
        "#440f76",
        "#721f81",
        "#9e2f7f",
        "#cd3f71",
        "#f1605d",
        "#fd9567",
        "#fec98d",
        "#fcfdbf",
      ],
      domain: [
        "-809",
        "-719",
        "-630",
        "-540",
        "-450",
        "-361",
        "-271",
        "-181",
        "-92",
        "-2",
      ],
    },
    meanLegend: {
      title: "Heating Degree Days",
      range: [
        "#e8e8e8",
        "#e2eef9",
        "#cde0f2",
        "#b0d2e8",
        "#89bfdd",
        "#60a6d2",
        "#3e8ec4",
        "#2172b6",
        "#0a549e",
        "#08306b",
      ],
      domain: [
        "0",
        "398",
        "796",
        "1193",
        "1591",
        "1989",
        "2387",
        "2784",
        "3182",
        "3580",
      ],
    },
  },
  // extreme rainfall
  // {
  //   id: 7,
  //   tileTitle: "Extreme Rainfall",
  //   tileImage:
  //     "https://njclimateresourcecenter.rutgers.edu/wp-content/uploads/2022/04/Precipitation-icon.png",
  //   what: "More about extreme rainfall",
  //   param: "extremeRain",
  //   title: "Climate Change Impacts on Extreme Rainfall Events in New Jersey",
  //   description: <div style={{ maxWidth: '900px' }}>

  //     <p>With climate change, warmer temperatures allow the atmosphere to hold more water vapor, potentially increasing the rainfall from a single event or storm system. Extreme rainfall has been increasing in frequency and magnitude in New Jersey in recent decades compared to the prior century and this trend is projected to continue through 2100 with both moderate and high greenhouse gas emissions. Future projections of extreme rainfall within New Jersey indicate continued intensification of extreme 24-hour rainfall events that have a 50%, 10% and 1% chance of occurring annually (with the lower percent change indicating more extreme rainfall). The 24-hour event represents the amount of rainfall that accumulated in a 24-hour period. Assuming moderate emissions, the median projection for the magnitude of the 24-hour rainfall event with a 1% chance of occurring annually will increase modestly by 2.5-10% in central and coastal New Jersey and by a larger 20-25% in northern New Jersey by the end of the century. Higher frequency events, such as the 24-hour rainfall events with a 50% and 10% chance of occurring annually are projected to have an average 7.5-15% increase in rainfall by 2100.</p><p>
  //       Heavy rainfall can cause flooding and lead to impacts such as power outages, property damage, displacement of/harm to residents, and/or crop loss. Flooding has compounding indirect effects through avenues such as carbon monoxide poisoning (personal power generator use after a flood) or contaminating food and water supplies. Extreme rainfall can increase surface water turbidity and bacteria contaminants that can be ingested, causing gastrointestinal illnesses. Frequent and intense precipitation can also lead to worse agricultural outcomes such as reduced plant growth, delayed planting, and soil saturation.
  //     </p>
  //   </div>,
  //   topText:
  //     <div style={{ maxWidth: '900px' }} ><p style={{ fontSize: '1.5rem' }}>
  //       Extreme rainfall represents the amount of rain received in a location that is considered much higher than average, often created by a large storm. In this tool, the extreme rainfall amount is defined as the rainfall accumulated within a 24-hour period that is excessively higher than what is considered average for the location. Projections of extreme rainfall are provided for a lower (RCP 4.5) and higher (RCP 8.5) greenhouse gas emissions scenario for the mid-21st century (2020–2069) and end 21st century (2050–2099). Extreme rainfall can often lead to both flash flooding and riverine flooding that can harm people and property. Extreme rainfall amounts are projected to increase by 2100, with higher emissions generating a greater increase in extreme rainfall.
  //     </p></div>,
  //   belowViz: '',

  //   statsTitle: "Extreme Rainfall Events",
  //   statsUnit: "inches",
  //   chartTitle: "Extreme Rainfall Events",
  //   chartYAxisText: "inches per event",
  //   chartYAxisMax: 15,

  //   about: {
  //     desc: `With this tool, the simulated extreme rainfall amounts can be viewed by municipality over historical and future time periods. Note the historical period of 1950–1999 represents climate model data calibrated to observational data but are not based solely on observed precipitation measurements. The future time periods are simulated using greenhouse gas emissions scenarios RCP 4.5 (moderate emissions) and RCP 8.5 (high emissions). Projection values for the 24-hour return level events (2-year, 10-year, 100-year, etc.) were fit to a logarithmic curve for each location so that the user can query non-explicitly modeled return periods and input a specific rainfall amount to see how the frequency of that amount may change in the future.`,
  //     variables: [
  //       {
  //         title: "Extreme Rainfall Events (inches)",
  //         desc: [
  //           <>
  //             <p>Please see DeGaetano(2021)[https://dspace.njstatelib.org/handle/10929/93913] for full details of the modeling methodology. Daily rainfall amounts were simulated across New Jersey and output at the center of each municipality. From these data, extreme values associated with the 24-hour return level events (2-year, 10-year, 100-year, etc.) were computed, following a methodology similar to the U.S. National Oceanic and Atmospheric Administration's ATLAS 14 dataset (<a href='https://hdsc.nws.noaa.gov/hdsc/pfds/pfds_map_cont.html' target='_blank' rel='noreferrer'>https://hdsc.nws.noaa.gov/hdsc/pfds/pfds_map_cont.html</a>). Historical average extreme rainfall values are displayed with the 5-95th confidence bounds in parentheses to the right. Median projection values are displayed with the 10-90th percentile range in parentheses to the right. This difference in confidence ranges is due to differences in fit methodology and are considered relatively close for the purpose of hazard planning. </p>
  //             <p>
  //               Original extreme rainfall projections were generated by the U.S. National Oceanic and Atmospheric Administration's Northeast Regional Climate Center and the New Jersey Department of Environmental Protection. Full documentation can be found here: <a href='https://dspace.njstatelib.org/handle/10929/93913' target="_blank" rel='noreferrer'>https://dspace.njstatelib.org/handle/10929/93913</a></p><p>
  //               Observation-based historical extreme rainfall values are derived from the U.S. National Oceanic and Atmospheric Administration's ATLAS 14 dataset <a href='https://hdsc.nws.noaa.gov/hdsc/pfds/pfds_map_cont.html' target='_blank' rel="noreferrer">(https://hdsc.nws.noaa.gov/hdsc/pfds/pfds_map_cont.html)</a>.</p>
  //             {/* <p>
  //               These data were subsequently modified for use in the NJADAPT extreme rainfall tool. Projection values for the 24-hour return level events (2-year, 10-year, 100-year, etc.) were fit to a logarithmic curve for each location so that the user can query non-explicitly modeled return periods and input a specific rainfall amount to see how the frequency of that amount may change in the future. This effort was completed in coordination with the U.S. National Oceanic and Atmospheric Administration's Northeast Regional Climate Center at Cornell University.
  //             </p> */}
  //           </>,
  //         ],
  //       },
  //     ],
  //   },
  // },
  // // heat waves
  // {
  //   id: 8,
  //   tileTitle: "Heat Waves",
  //   tileImage:
  //     "https://njclimateresourcecenter.rutgers.edu/wp-content/uploads/2022/04/fan-icon.png",
  //   what: "More about heat waves",
  //   param: "heatWaves",
  //   title: "Climate Change Impacts on Heat Waves in New Jersey",
  //   description: <div><p>Heatwaves have a direct impact on human health, the local environment, and the economy. Heat stress from extreme heat events is the leading cause of weather-related deaths in the United States. A heatwave represents time when the ambient temperatures often outpace the body's ability to cool itself via perspiration, resulting in greater incidences of heat-related illnesses, hospital admissions, and deaths among vulnerable populations. Additionally, heatwaves can result in crop failures, excessive load on the electricity grid from increased air conditioner use, and can affect the health of the local ecosystem. The average number and duration of heatwaves have been increasing across the U.S. in recent decades and this trend is expected to continue with climate change. Planning for emergency response to future heatwaves will necessitate projections of how heatwaves may change in New Jersey counties over the coming century. This dataset provides a measure of change in future heatwave number and duration compared to a historical period of 1981-2010.</p></div>,
  //   topText: <div><p style={{ fontSize: '1.6rem' }}>A heatwave is broadly defined as a period of unusually hot weather lasing two to three or more days. This hot weather is typically warmer than the historical averages for the affected region and is also much warmer than the temperatures preceding and following the heatwave event.  In general, under both a moderate and high emissions scenarios, the climate warms and heatwaves become stronger, but the high emissions scenario creates much higher temperatures and greater heatwaves. </p><p style={{ fontSize: '1.6rem', marginTop: '2rem' }}>Included in this table are the predicted values followed by the confidence interval in parentheses.</p></div>,
  //   belowViz:
  //     "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Enim, tempora. Minima reprehenderit ad explicabo odio vero? Non necessitatibus nesciunt, provident laboriosam, architecto magni ullam, cum animi voluptate commodi praesentium. Incidunt sit nam quibusdam aliquid. Minus provident molestiae cumque rem placeat, aspernatur repudiandae vel voluptas alias consequuntur reiciendis quod! Sapiente, dolor.",
  //   statsTitle: "Heat Wave Events",
  //   statsUnit: "days",
  //   chartTitle: "Heat Wave Events",
  //   chartYAxisText: "days per event",
  //   // chartYAxisMax: 15,

  //   about: {
  //     desc: <><p>The data in this analysis are composed of an observational dataset and a series of climate model outputs. The observational data is a gridded dataset of about 20,000 NOAA Cooperative Observer (COOP) stations across the conterminous United States. NOAA's Applied Climate Information System <a href='http://www.rcc-acis.org/' target='_blank' rel="noreferrer">(ACIS, http://www.rcc-acis.org/)</a> was used to extract the daily average temperature for the State of New Jersey for over 1981-2010 to establish a historical baseline of annual heatwave statistics. These daily temperatures were averaged by county such that each county had a single record of daily average temperatures from 1981-2010.</p><p>
  //       The modeled data were also retrieved through ACIS, again extracting daily average temperature data by county. The modeled data was composed of 31 individual climate models that are part of the Coupled Model Intercomparison Project 5 and were scaled regionally to New Jersey using the Localized Constructed Analogues (LOCA) method. The climate models provide data for three separate time periods. The first was a historical period (1981-2010) to correspond to the observational data. The other two were future time periods: 2036-2065 to represent the mid-21 st century and 2070-2099 to represent the end of the 21 st century.</p><p>
  //         The projected future climates were modeled using 2 different greenhouse gas emissions scenarios. The first, Representative Concentration Pathway (RCP) 4.5 represents moderate greenhouse gas emissions though the end of the century. 13 The second, RCP 8.5 represents unmitigated and growing greenhouse gas emissions by the end of the century 14 and is generally considered the upper plausible limit of the magnitude of climate change. In general, under both scenarios, the climate warms and heatwaves become stronger, but RCP 8.5 creates much higher temperatures and greater heatwaves.</p>
  //       <p>For more information see the full metadata at <a href="https://njhazadapt.rutgers.edu/files/metadata/Heatwave_Dataset_Metadata_v4.pdf" target="_blank" rel='noreferrer'>https://njhazadapt.rutgers.edu/files/metadata/Heatwave_Dataset_Metadata_v4.pdf</a>.</p>
  //     </>,
  //     variables: [
  //       {
  //         title: "",
  //         desc: [

  //         ],
  //       },
  //     ],
  //   },
  // },
  //slr 
  // {
  //   id: 9,
  //   tileTitle: "Sea Level Rise",
  //   param: "slr",
  //   tileImage:
  //     "https://njclimateresourcecenter.rutgers.edu/wp-content/uploads/2022/04/Precipitation-icon.png",
  //   title:
  //     "Sea Level Rise in New Jersey ",
  //   what: "More about sea level rise", 
  //   description: `With climate change, sea level rise.`,
  //   topText:
  //     "SLR is... .",
  //   belowViz:
  //     "SLR [in every season is highly variable from year to year in New Jersey. The range of projections is very wide, highlighting how variable and uncertain projections in mean seasonal rainfall are. However, what is more certain is that extreme rainfall is projected to become more intense throughout the rest of the century. An example of an extreme rainfall event would be where the average amount of 3 months worth of rain falls in a 3 hour period.]",
  //   mapTitle: "Rise in Sea Level (feet)",
  //   about: {
  //     desc: `With this tool, sea level rise can be viewed for the whole of New Jersey over historical and future time periods. Note the historical period of 1991-2020 is climate model data and not based solely on observed precipitation measurements. `,
  //     variables: [
  //       {
  //         title: "Mean Precipitation (inches)",
  //         desc: [
  //           "Mean daily precipitation is simulated using a grid over the state. The mean daily precipitation is totaled for the selected season at each grid cell. The statewide seasonal mean is calculated by averaging these grid cell values over the whole state. The 10th through 90th percentile range of simulated seasonal mean precipitation is represented by the light blue shading.",
  //         ],
  //       },
  //     ],
  //   },
  //   changeLegend: {
  //     title: "Change in feet",
  //     range: [
  //       "#a6611a",
  //       "#c3924b",
  //       "#dfc27d",
  //       "#eadcb9",
  //       "#f5f5f5",
  //       "#bbe1db",
  //       "#80cdc1",
  //       "#40a999",
  //       "#018571",
  //     ],
  //     domain: ["+1", "+2", "+3", "+4"],
  //   },
  //   meanLegend: {
  //     title: "Precipitation in inches",
  //     range: [
  //       "#e8e8e8",
  //       "#e2eef9",
  //       "#cde0f2",
  //       "#b0d2e8",
  //       "#89bfdd",
  //       "#60a6d2",
  //       "#3e8ec4",
  //       "#2172b6",
  //       "#0a549e",
  //       "#08306b",
  //     ],
  //     domain: ["9", "10", "11", "12", "13", "14", "15", "16", "17", "18"],
  //   },
  //   portalId: 'be2a1126924640808cb9925c48b87828'
  // },
];

export default pagesArray;
