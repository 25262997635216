import { useContext, useEffect, useState, useRef } from "react"
import AreaRangeHighchart from "./AreaRangeHighchart/AreaRangeHighchart"
import AppContext from "../../AppContext"
import ScenarioBlurb from "../ScenarioBlurb"
import { span } from "../../data/variables"
import rcc5Year from '../../data/staticData/rcc5Year.csv'
import historical from '../../data/staticData/historical.csv'
import { parse } from "papaparse"

const ChartSection = () => {

    const [state] = useContext(AppContext)
    const { activeParam: { param }, activeRcp: { chartVar: scenario }, activeSeason: { chartVar: season } } = state

    const [chartData, setChartData] = useState([])
    const [chartHist, setChartHist] = useState([])

    const effectRunCount = useRef(0);

    useEffect(() => {
        const currentEffectRun = ++effectRunCount.current;
        let ignore = false;

        const avgConfig = {
            complete: (results) => {
                if (!ignore && currentEffectRun === effectRunCount.current){
                    console.log(param, scenario, season)
                    const newAverages = results.data.filter((row) => row.rcp === scenario && Number(row.month) === season).map((row) => [Number(row.year), Number(row[`${param}_wmean`])]);
                    
                        setChartData(prev => ({...prev, averages: newAverages}));
                    
                }
            },
            header: true,
            download: true,
            error: (error, file) => {
                console.log('error while parsing', error, file);
            }
        };

        const rangeConfig = {
            complete: (results) => {
                if (!ignore && currentEffectRun === effectRunCount.current){
                    const newRanges = results.data.filter((row) => row.rcp === scenario && Number(row.month) === season).map((row) => [Number(row.year), Number(row[`${param}_all10`]), Number(row[`${param}_all90`])]);
                    setChartData(prev => ({ ...prev, ranges: newRanges }));
                }
            },
            header: true,
            download: true,
            error: (error, file) => {
                console.log('error while parsing', error, file);
            }
        };

        const histConfig = {
            complete: (results) => {
                if (!ignore && currentEffectRun === effectRunCount.current){
                    console.log(param, scenario, season)
                    const newHist = results.data.filter((row) => Number(row.month) === season).map((row) => [Number(row.year), Number(row[`${param}_prism`])]);
                    setChartHist(newHist);
                }
            },
            header: true,
            download: true,
            error: (error, file) => {
                console.log('error while parsing', error, file);
            }
        };

        parse(historical, histConfig);
        parse(rcc5Year, avgConfig);
        parse(rcc5Year, rangeConfig);

        return () => {
            ignore = true;
        }
    }, [param, scenario, season]);

    
    let yearRange = [];
    yearRange.push(state.activeYear.chartVar - (span - 1));
    yearRange.push(state.activeYear.chartVar);

    return (
                <div style={{ padding: '2rem' }}>
                    <AreaRangeHighchart color={state.activeColors}
                        chartOptions={{
                            margin: 0,
                            title: {
                                text: `Modeled Statewide ${state.activeSeason.mapName} ${state.activeParam?.chartTitle}`,
                            },

                            xAxis: {
                                labels: {
                                    formatter() {
                                        return this.pos;
                                    },
                                },
                                plotBands: [
                                    {
                                        color: "#f1f1f1",
                                        from: yearRange[0],
                                        to: yearRange[1],
                                    },
                                ],
                            },
                            yAxis: {
                                title: {
                                    text: state.activeParam?.chartYAxisText,
                                },
                                max: state.activeParam?.chartYAxisMax,
                                labels: {
                                    formatter: function () {
                                        return this.value;
                                    },
                                },
                            },
                            tooltip: {
                                crosshairs: true,
                                shared: true,
                            },
                            series: [
                                {
                                    name: "Range",
                                    type: "arearange",
                                    data: chartData.ranges,
                                    lineWidth: 0,
                                    linkedTo: "main",
                                    color: `${state.activeColors.background}`,
                                    fillOpacity: 0.3,
                                    marker: {
                                        enabled: false,
                                    },
                                },
                                {
                                    id: "main",
                                    dashStyle: "Dash",
                                    name: "Projected Values",
                                    data: chartData.averages,
                                    lineColor: state.activeColors.background,
                                    marker: {
                                        fillColor: state.activeColors.background,
                                    },
                                },

                                {
                                    name: "Historical Values",
                                    type: "line",
                                    data: chartHist,
                                    lineColor: "#9a9b9c",
                                    marker: {
                                        fillColor: "#9a9b9c",
                                    },
                                },
                            ],
                        }} />
                    <ObservedBlurb />
                    <ScenarioBlurb rcp={state.activeRcp.chartVar} />
                </div>
              )
}
export default ChartSection

const ObservedBlurb = () => (
    <div style={{ margin: "2rem" }}>
        The above chart shows the projections of 32 climate models. The broad
        colored band shows the range of values that the models projected. The
        narrower the band, the more strongly models agree. The dashed line shows the
        average of those projections. The solid line shows the actual values that
        already occurred. They do not line up exactly with the average modeled
        values but do fall within the range, indicating the models are projecting
        realistic values.
    </div>
);